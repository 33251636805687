import { createStore } from "vuex";
import {
  getToken,
  removeToken,
  getStationId,
  setStationId,
  setUserType,
  getUserType
} from "@/utils/auth";

export default createStore({
  state: {
    token: getToken(),
    name: "",
    stationId: getStationId(),
    userType: getUserType(),
    closed: false,
  },
  mutations: {
    setClosed(state, closed) {
      state.closed = closed;
    },
    setStationIds(state, stationId) {
      state.stationId = stationId;
      setStationId(stationId);
    },
    setUserType(state, userType) {
      state.userType = userType;
      setUserType(userType);
    }
  },
  getters: {
    getClosed: (state) => state.closed,
    getMessages: (state) => state.messages,
    getOnline: (state) => state.online,
    getStation: (state) => state.stationId,
    getUserType: (state) => state.userType,
  },
  actions: {
    // 退出系统
    LogOut({ commit }) {
      return new Promise((resolve, reject) => {
        try {
          commit("SET_TOKEN", "");
          removeToken();
          resolve();
        } catch (err) {
          reject(err);
        }
      });
    },
  },
  modules: {},
});

import Cookies from "js-cookie";

const TokenKey = "H5-Token";

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

export function setStationId(stationId) {
  return Cookies.set("stationId", stationId);
}

export function getStationId() {
  return Cookies.get("stationId");
}

export function setOpenid(openid) {
  return Cookies.set("openid", openid);
}

export function getOpenId() {
  return Cookies.get("openid");
}

export function setUserType(type) {
  return Cookies.set("usertype", type);
}

export function getUserType() {
  return Cookies.get("usertype");
}

export function removeUserType() {
  return Cookies.remove("usertype");
}

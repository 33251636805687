<template>
  <div id="NavBar">
    <van-nav-bar
      :title="title"
      :border="false"
      :left-arrow="isleftarrow"
      @click-left="onClickLeft"
    />
  </div>
</template>
<script setup name="Header">
import { useRouter } from "vue-router";
import { defineProps, toRefs } from "vue";

const props = defineProps({
  title: String,
  isleftarrow: Boolean,
  hasBg: Boolean,
});
const { title, isleftarrow } = toRefs(props);
let router = useRouter();
const onClickLeft = () => {
  router.go(-1);
};
// export default {
//   // title：用来显示导航栏的title，isleftarrow用来显示导航栏的左侧返回箭头
//   props: ['title', 'isleftarrow','hasBg'],
//   methods: {
//     onClickLeft () {
//       // 点击回退的时候当做地址回退
//       this.$router.go(-1)
//     }
//   }
// }
</script>
<style scoped>
#NavBar {
  /* position: fixed;
  top: 0;
  left: 0;
  right: 0; */
}
.bgcolor {
  background: #fff;
}
.van-nav-bar {
  background: #fff;
}
</style>

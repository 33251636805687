import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/login",
    name: "Login",
    meta: {
      tabbarShow: false,
      navShow: false,
      keepAlive: false,
      title: "登录",
      isleftarrow: false,
    },
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/Login.vue"),
  },
  {
    path: "/authorize",
    name: "Authorize",
    meta: {
      tabbarShow: false,
      navShow: false,
      keepAlive: false,
      title: "微信授权",
      isleftarrow: false,
    },
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/Authorize.vue"),
  },
  {
    path: "/bindphone",
    name: "Bindphone",
    meta: {
      tabbarShow: false,
      navShow: true,
      keepAlive: false,
      title: "绑定手机号",
      isleftarrow: true,
    },
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/Bindphone.vue"),
  },
  {
    path: "/",
    name: "Home",
    meta: {
      tabbarShow: true,
      navShow: false,
      keepAlive: true,
      title: "首页",
      isleftarrow: false,
    },
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  {
    path: "/fawu",
    name: "Fawu",
    meta: {
      tabbarShow: true,
      navShow: false,
      keepAlive: true,
      title: "法务培训",
      isleftarrow: false,
    },
    component: () => import(/* webpackChunkName: "home" */ "../views/Fawu.vue"),
  },
  {
    path: "/law",
    name: "Law",
    meta: {
      tabbarShow: true,
      navShow: false,
      keepAlive: true,
      title: "劳动法普及",
      isleftarrow: false,
    },
    component: () => import(/* webpackChunkName: "home" */ "../views/Law.vue"),
  },
  {
    path: "/person",
    name: "Person",
    meta: {
      tabbarShow: true,
      navShow: false,
      keepAlive: true,
      title: "个人中心",
      isleftarrow: false,
    },
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/Person.vue"),
  },
  {
    path: "/applyContent",
    name: "ApplyContent",
    meta: {
      tabbarShow: false,
      navShow: true,
      keepAlive: true,
      title: "我的申请",
      isleftarrow: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "home" */ "../views/contentPage/ApplyContent.vue"
      ),
  },
  {
    path: "/companyApplyContent",
    name: "CompanyApplyContent",
    meta: {
      tabbarShow: false,
      navShow: true,
      keepAlive: true,
      title: "我的申请",
      isleftarrow: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "home" */ "../views/contentPage/CompanyApplyContent.vue"
        ),
  },
  {
    path: "/chooseIdentity",
    name: "ChooseIdentity",
    meta: {
      tabbarShow: false,
      navShow: false,
      keepAlive: true,
      title: "选择身份",
      isleftarrow: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "home" */ "../views/chooseiden/ChooseIdentity.vue"
      ),
  },
  {
    path: "/companyIdentity",
    name: "CompanyIdentity",
    meta: {
      tabbarShow: false,
      navShow: true,
      keepAlive: true,
      title: "填写信息",
      isleftarrow: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "home" */ "../views/chooseiden/CompanyIdentity.vue"
      ),
  },
  {
    path: "/personIdentity",
    name: "PersonIdentity",
    meta: {
      tabbarShow: false,
      navShow: true,
      keepAlive: true,
      title: "填写信息",
      isleftarrow: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "home" */ "../views/chooseiden/PersonIdentity.vue"
      ),
  },
  {
    path: "/workerApply",
    name: "WorkerApply",
    meta: {
      tabbarShow: false,
      navShow: true,
      keepAlive: true,
      title: "调解申请",
      isleftarrow: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "home" */ "../views/apply/worker/WokerApply.vue"
      ),
  },
  {
    path: "/workerApplyFirst",
    name: "WorkerApplyFirst",
    meta: {
      tabbarShow: false,
      navShow: true,
      keepAlive: true,
      title: "调解申请",
      isleftarrow: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "home" */ "../views/apply/worker/WokerApplyFirst.vue"
      ),
  },
  {
    path: "/workerApplySecond",
    name: "WorkerApplySecond",
    meta: {
      tabbarShow: false,
      navShow: true,
      keepAlive: true,
      title: "调解申请",
      isleftarrow: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "home" */ "../views/apply/worker/WokerApplySecond.vue"
      ),
  },
  {
    path: "/workerApplyThird",
    name: "WorkerApplyThird",
    meta: {
      tabbarShow: false,
      navShow: true,
      keepAlive: true,
      title: "调解申请",
      isleftarrow: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "home" */ "../views/apply/worker/WokerApplyThird.vue"
      ),
  },
  {
    path: "/fawuList",
    name: "FawuList",
    meta: {
      tabbarShow: false,
      navShow: true,
      keepAlive: true,
      title: "劳动法相关培训课程",
      isleftarrow: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "home" */ "../views/contentPage/FawuList.vue"
      ),
  },
  {
    path: "/fawuContent",
    name: "FawuContent",
    meta: {
      tabbarShow: false,
      navShow: true,
      keepAlive: true,
      title: "法务培训",
      isleftarrow: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "home" */ "../views/contentPage/FawuContent.vue"
      ),
  },
  {
    path: "/lawContent",
    name: "LawContent",
    meta: {
      tabbarShow: false,
      navShow: true,
      keepAlive: true,
      title: "劳动法普及",
      isleftarrow: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "home" */ "../views/contentPage/LawContent.vue"
      ),
  },
  {
    path: "/workerApplyFourth",
    name: "WorkerApplyFourth",
    meta: {
      tabbarShow: false,
      navShow: false,
      keepAlive: true,
      title: "调解申请",
      isleftarrow: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "home" */ "../views/apply/worker/WokerApplyFourth.vue"
      ),
  },
  {
    path: "/companyApply",
    name: "CompanyApply",
    meta: {
      tabbarShow: false,
      navShow: true,
      keepAlive: true,
      title: "调解申请",
      isleftarrow: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "home" */ "../views/apply/company/CompanyApply.vue"
      ),
  },
  {
    path: "/companyApplyFirst",
    name: "CompanyApplyFirst",
    meta: {
      tabbarShow: false,
      navShow: true,
      keepAlive: true,
      title: "调解申请",
      isleftarrow: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "home" */ "../views/apply/company/CompanyApplyFirst.vue"
      ),
  },
  {
    path: "/companyApplySecond",
    name: "CompanyApplySecond",
    meta: {
      tabbarShow: false,
      navShow: true,
      keepAlive: true,
      title: "调解申请",
      isleftarrow: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "home" */ "../views/apply/company/CompanyApplySecond.vue"
      ),
  },
  {
    path: "/companyApplyThird",
    name: "CompanyApplyThird",
    meta: {
      tabbarShow: false,
      navShow: true,
      keepAlive: true,
      title: "调解申请",
      isleftarrow: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "home" */ "../views/apply/company/CompanyApplyThird.vue"
      ),
  },
  {
    path: "/companyApplyFourth",
    name: "CompanyApplyFourth",
    meta: {
      tabbarShow: false,
      navShow: false,
      keepAlive: true,
      title: "调解申请",
      isleftarrow: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "home" */ "../views/apply/company/CompanyApplyFourth.vue"
      ),
  },
  {
    path: "/search",
    name: "Search",
    meta: {
      tabbarShow: false,
      navShow: false,
      keepAlive: true,
      title: "调解申请",
      isleftarrow: true,
    },
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/search/Search.vue"),
  },
  {
    path: "/changeNickName",
    name: "ChangeNickName",
    meta: {
      tabbarShow: false,
      navShow: true,
      keepAlive: true,
      title: "编辑昵称",
      isleftarrow: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "home" */ "../views/center/ChangeNickName.vue"
      ),
  },
  {
    path: "/changeHeader",
    name: "ChangeHeader",
    meta: {
      tabbarShow: false,
      navShow: true,
      keepAlive: true,
      title: "修改头像",
      isleftarrow: true,
    },
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/center/ChangeHeader.vue"),
  },
  {
    path: "/setting",
    name: "Setting",
    meta: {
      tabbarShow: false,
      navShow: true,
      keepAlive: true,
      title: "设置",
      isleftarrow: true,
    },
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/center/Setting.vue"),
  },
  {
    path: "/myApply",
    name: "MyApply",
    meta: {
      tabbarShow: false,
      navShow: true,
      keepAlive: true,
      title: "我的申请",
      isleftarrow: true,
    },
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/center/MyApply.vue"),
  },
  {
    path: "/workerAuth",
    name: "WokerAuth",
    meta: {
      tabbarShow: false,
      navShow: true,
      keepAlive: true,
      title: "个人认证",
      isleftarrow: true,
    },
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/center/WorkerAuth.vue"),
  },
  {
    path: "/companyAuth",
    name: "CompanyAuth",
    meta: {
      tabbarShow: false,
      navShow: true,
      keepAlive: true,
      title: "企业认证",
      isleftarrow: true,
    },
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/center/CompanyAuth.vue"),
  },
  {
    path: "/helpAndFeedBack",
    name: "HelpAndFeedBack",
    meta: {
      tabbarShow: false,
      navShow: true,
      keepAlive: true,
      title: "帮助与反馈",
      isleftarrow: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "home" */ "../views/center/HelpAndFeedBack.vue"
      ),
  },
  {
    path: "/feedBack",
    name: "FeedBack",
    meta: {
      tabbarShow: false,
      navShow: true,
      keepAlive: true,
      title: "反馈问题",
      isleftarrow: true,
    },
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/center/FeedBack.vue"),
  },
  {
    path: "/fawuSearch",
    name: "FawuSearch",
    meta: {
      tabbarShow: false,
      navShow: false,
      keepAlive: true,
      title: "法务培训",
      isleftarrow: true,
    },
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/search/FawuSearch.vue"),
  },
  {
    path: "/workerApplyBefore",
    name: "WorkerApplyBefore",
    meta: {
      tabbarShow: false,
      navShow: true,
      keepAlive: true,
      title: "调解申请",
      isleftarrow: true,
    },
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/apply/worker/WokerApplyBefore.vue"),
  },
  {
    path: "/companyApplyBefore",
    name: "CompanyApplyBefore",
    meta: {
      tabbarShow: false,
      navShow: true,
      keepAlive: true,
      title: "调解申请",
      isleftarrow: true,
    },
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/apply/company/CompanyApplyBefore.vue"),
  },
  {
    path: "/protocol",
    name: "Protocol",
    meta: {
      tabbarShow: false,
      navShow: true,
      keepAlive: true,
      title: "用户协议",
      isleftarrow: true,
    },
    component: () =>
        import(/* webpackChunkName: "home" */ "../views/Protocol.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
